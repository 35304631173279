<template>
    <div>
        <header-portrate />
        <exportPrintVat ref="exportbar" />
    <div style="margin-bottom:70px !important;">
        <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" />
        
        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:20px !important;`">
            <v-col
            cols="12"
            md="10"
            sm="12">
            <form autocomplete="off">
                <v-row>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.start_date}}</label>
                        <b-input-group>
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.end_date}}</label>
                        <b-input-group>
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList" />
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
                        </b-input-group>
                    </v-col>

                    <v-col cols="12" md="2" sm="12">
                        <label></label>
                        <b-input-group style="">
                        <b-button variant="light" style="padding-top:2px !important; padding-bottom:2px !important;background:blue;color:#fff;width:75px;"  @click="getReport()">{{lang.search}}</b-button>
                        </b-input-group>
                    </v-col>
                </v-row>
            </form>
            <b-input-group class="mt-3" style="display:none">
                <b-form-input placeholder="Start Date" style="border:1px solid #000;border-radius:0px" type="date" v-model="sdate"></b-form-input>
                <b-form-input placeholder="End Date" style="border:1px solid #000" type="date" v-model="edate"></b-form-input>
                <b-input-group-append style="border:1px solid #000">
                    <b-button variant="light" style="background: black !important;color:#fff;font-size:14px;border-radius:0px" @click="getReport()">{{lang.search}}</b-button>
                </b-input-group-append>
            </b-input-group>
            </v-col>

        </v-row>
        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-7px !important;`">
            <v-col cols="12" md="6" sm="12">
               <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="5" class="text-center" style="background:#000 !important;color:#fff !important;font-size:1.1rem;">{{lang.sales_invoices}}</th>
                        </tr>
                        <tr>
                            <th class="text-center" style="width:120px;">{{lang.inv_date}}</th>
                            <th class="text-center">{{lang.invoiceno}}</th>
                            <th class="text-center" style="width:120px;">{{lang.total}}</th>
                            <th class="text-center" style="width:120px;border-bottom:1px solid #fff !important;color:#fff !important; background:red !important">{{lang.sales_vat}}</th>
                            <th class="text-center" style="width:120px;">{{lang.ftotal}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,ind) in invRows" :key="ind">
                            <td class="text-center">{{ item.inv_date.substr(0, 10) }}</td>
                            <td class="text-center">{{ item.invoice_number }}</td>
                            <td :style="item.fontcolor" class="text-center">{{ item.total }}</td>
                            <td :style="item.fontcolor + `;background:lightgreen; !important;border-bottom:1px solid #fff !important;`" class="text-center">{{ item.vat }}</td>
                            <td :style="item.fontcolor" class="text-center">{{ item.ftotal }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr style="background:#f39292">
                            <th class="text-center" style="background:#000 !important;color:#fff !important;"></th>
                            <th class="text-center" style="background:#000 !important;color:#fff !important;">{{lang.totals}}</th>
                            <th class="text-center" style="background:#000 !important;color:#fff !important;">{{ _d_inv.total }}</th>
                            <th class="text-center" style="background:red !important;color:#fff !important;border-top:1px solid #fff !important;">{{ _d_inv.vat }}</th>
                            <th class="text-center" style="background:#000 !important;color:#fff !important;">{{ _d_inv.ftotal }}</th>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </v-col>
            
            <v-col cols="12" md="6" sm="12">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="5" class="text-center" style="background:#000 !important;color:#fff !important;font-size:1.1rem;">{{lang.return_invoices}}</th>
                        </tr>
                        <tr>
                            <th class="text-center" style="width:120px;">{{lang.inv_date}}</th>
                            <th class="text-center">{{lang.invoiceno}}</th>
                            <th class="text-center" style="width:120px;">{{lang.total}}</th>
                            <th class="text-center" style="width:120px;border-bottom:1px solid #fff !important;color:#fff !important; background:green !important">{{lang.sales_vat}}</th>
                            <th class="text-center" style="width:120px;">{{lang.ftotal}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,ind) in retres" :key="ind">
                            <td class="text-center">{{ item.inv_date.substr(0, 10) }}</td>
                            <td class="text-center">{{ item.invoice_number }}</td>
                            <td :style="item.fontcolor" class="text-center">{{ item.total }}</td>
                            <td :style="item.fontcolor + `;background:lightgreen; !important;border-bottom:1px solid #fff !important;`" class="text-center">{{ item.vat }}</td>
                            <td :style="item.fontcolor" class="text-center">{{ item.ftotal }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr style="background:#f39292">
                            <th class="text-center" style="background:#000 !important;color:#fff !important;"></th>
                            <th class="text-center" style="background:#000 !important;color:#fff !important;">{{lang.totals}}</th>
                            <th class="text-center" style="background:#000 !important;color:#fff !important;">{{ _d_ret.total }}</th>
                            <th class="text-center" style="background:green !important;color:#fff !important;border-top:1px solid #fff !important;">{{ _d_ret.vat }}</th>
                            <th class="text-center" style="background:#000 !important;color:#fff !important;">{{ _d_ret.ftotal }}</th>
                        </tr>
                    </tfoot>
                </v-simple-table>
                <v-row><v-col><br></v-col></v-row>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="5" class="text-center" style="background:#000 !important;color:#fff !important;font-size:1.1rem;">{{lang.purchase_bill}}</th>
                        </tr>
                        <tr>
                            <th class="text-center" style="width:120px;">{{lang.inv_date}}</th>
                            <th class="text-center">{{lang.invoiceno}}</th>
                            <th class="text-center" style="width:120px;">{{lang.total}}</th>
                            <th class="text-center" style="width:120px;color:#fff !important; background:green !important;border-bottom:1px solid #fff !important;">{{lang.bill_vat}}</th>
                            <th class="text-center" style="width:120px;">{{lang.ftotal}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,ind) in purRows" :key="ind">
                            <td class="text-center" style="width:120px;">{{ item.bill_date.substr(0, 10) }}</td>
                            <td class="text-center">{{ item.bill_number }}</td>
                            <td class="text-center" style="width:120px;">{{ item.total }}</td>
                            <td class="text-center" style="width:120px;background:lightgreen !important;color:#000 !important;border-bottom:1px solid #fff !important;">{{ item.vat }}</td>
                            <td class="text-center" style="width:120px;">{{ item.ftotal }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr style="background:#92f395">
                            <th class="text-center" style="background:#000 !important;color:#fff !important;"></th>
                            <th class="text-center" style="background:#000 !important;color:#fff !important;">{{lang.totals}}</th>
                            <th class="text-center" style="background:#000 !important;color:#fff !important;">{{ _d_pur.total }}</th>
                            <th class="text-center" style="background:green !important;color:#fff !important;border-top:1px solid #fff !important;">{{ _d_pur.vat }}</th>
                            <th class="text-center" style="background:#000 !important;color:#fff !important;">{{ _d_pur.ftotal }}</th>
                        </tr>
                    </tfoot>
                </v-simple-table>

                <v-row><v-col><br></v-col></v-row>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="5" class="text-center" style="background:#000 !important;color:#fff !important;font-size:1.1rem;">{{lang.expenses_invoices}}</th>
                        </tr>
                        <tr>
                            <th class="text-center" style="width:120px;">{{lang.inv_date}}</th>
                            <th class="text-center">{{lang.invoiceno}}</th>
                            <th class="text-center" style="width:120px;">{{lang.total}}</th>
                            <th class="text-center" style="width:120px;color:#fff !important; background:green !important;border-bottom:1px solid #fff !important;">{{lang.bill_vat}}</th>
                            <th class="text-center" style="width:120px;">{{lang.ftotal}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,ind) in expense" :key="ind">
                            <td class="text-center">{{ item.bill_date.substr(0, 10) }}</td>
                            <td class="text-center">{{ item.bill_number }}</td>
                            <td class="text-center">{{ item.total }}</td>
                            <td class="text-center" style="background:lightgreen !important;color:#000 !important;border-bottom:1px solid #fff !important;">{{ item.vat }}</td>
                            <td class="text-center">{{ item.ftotal }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr style="background:#92f395">
                            <th class="text-center" style="background:#000 !important;color:#fff !important;"></th>
                            <th class="text-center" style="background:#000 !important;color:#fff !important;">{{lang.totals}}</th>
                            <th class="text-center" style="background:#000 !important;color:#fff !important;">{{ _d_exp.total }}</th>
                            <th class="text-center" style="background:green !important;color:#fff !important;border-top:1px solid #fff !important;">{{ _d_exp.vat }}</th>
                            <th class="text-center" style="background:#000 !important;color:#fff !important;">{{ _d_exp.ftotal }}</th>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-7px !important;`">
            <v-col cols="12" md="12" sm="12">
                <v-simple-table>
                    <thead>
                        <th colspan="5" class="text-center" style="background:blue !important;font-size:1.1rem;color:#fff !important">{{lang.vat_summary}}</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="border-left:none !important;">
                                <br>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th class="text-center" colspan="3" style="background:black !important;color:#fff !important;font-size:1.1rem;">{{lang.sales}}</th>
                                        </tr>
                                        <tr>
                                            <th class="text-center">{{lang.total}}</th>
                                            <th class="text-center"  style="background:red !important;border-bottom:1px solid #fff !important; color:#fff !important;">{{lang.vat}} 15%</th>
                                            <th class="text-center">{{lang.fftotal}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center">{{_d_inv.total}}</td>
                                            <td class="text-center" style="background:red !important;border-top:1px solid #fff !important; color:#fff !important;">{{_d_inv.vat}}</td>
                                            <td class="text-center">{{_d_inv.ftotal}}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </td>
                            <td style="border-left:none !important;border-right:none !important;">
                                <br>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th class="text-center" colspan="3" style="background:black !important;color:#fff !important;font-size:1.1rem;">{{lang.return_invoices}}</th>
                                        </tr>
                                        <tr>
                                            <th class="text-center">{{lang.total}}</th>
                                            <th class="text-center"  style="background:green !important;border-bottom:1px solid #fff !important; color:#fff !important;">{{lang.vat}} 15%</th>
                                            <th class="text-center">{{lang.fftotal}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center">{{_d_ret.total}}</td>
                                            <td class="text-center" style="background:green !important;border-top:1px solid #fff !important; color:#fff !important;">{{_d_ret.vat}}</td>
                                            <td class="text-center">{{_d_ret.ftotal}}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </td>
                            <td style="border-left:none !important;border-right:none !important;">
                                <br>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th class="text-center"  style="background:black !important; color:#fff !important;font-size:1.1rem;" colspan="3">{{lang.purchase_summary}}</th>
                                        </tr>
                                        <tr>
                                            <th class="text-center">{{lang.total}}</th>
                                            <th class="text-center" style="background:green !important;border-bottom:1px solid #fff !important; color:#fff !important;">{{lang.vat}} 15%</th>
                                            <th class="text-center">{{lang.fftotal}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center">{{_d_pur.total}}</td>
                                            <td class="text-center" style="background:green !important;border-top:1px solid #fff !important; color:#fff !important;">{{_d_pur.vat}}</td>
                                            <td class="text-center">{{_d_pur.ftotal}}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </td>
                            <td style="border-left:none !important;border-right:none !important;">
                                <br>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th class="text-center"  style="background:black !important; color:#fff !important;font-size:1.1rem;" colspan="3">{{lang.expenses}}</th>
                                        </tr>
                                        <tr>
                                            <th class="text-center">{{lang.total}}</th>
                                            <th class="text-center" style="background:green !important;border-bottom:1px solid #fff !important; color:#fff !important;">{{lang.vat}} 15%</th>
                                            <th class="text-center">{{lang.fftotal}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center">{{_d_exp.total}}</td>
                                            <td class="text-center" style="background:green !important;border-top:1px solid #fff !important; color:#fff !important;">{{_d_exp.vat}}</td>
                                            <td class="text-center">{{_d_exp.ftotal}}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </td>
                            <td style="border-right:none !important;">
                                <br>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th class="text-center" colspan="3" style="background:black !important; color:#fff !important;font-size:1.1rem;">{{lang.vat_required_return}}</th>
                                        </tr>
                                        <tr>
                                            <th class="text-center">{{lang.total}}</th>
                                            <th class="text-center" style="background:red !important;border-bottom:1px solid #fff !important; color:#fff !important;">{{lang.vat}} 15%</th>
                                            <th class="text-center">{{lang.fftotal}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center">{{summ.total}}</td>
                                            <td class="text-center" style="background:red !important;border-top:1px solid #fff !important; color:#fff !important;">{{summ.vat}}</td>
                                            <td class="text-center">{{summ.ftotal}}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
                
            </v-col>

        </v-row>

    </div>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios';
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import exportPrintVat from '@/components/exports-print-vat.vue'

export default{
    components: {BreadCrumbs, TabsComp, HeaderPortrate, Footer,exportPrintVat},
    data() {
        return {
            date: (new Date((new Date()).getFullYear(),(new Date()).getMonth(),2)).toISOString().substr(0, 10),
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            // sdate: (new Date((new Date()).getFullYear(),(new Date()).getMonth(),2)).toISOString().substr(0, 10),
            // edate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            modal1: false,
            active_tab:3,
            
            incomeRows: [],
            outRows: [],
            purRows: [],
            retres: [],
            invRows: [],
            expense: [],
            d_out:{
                total: 0,
                vat: 0,
                ftotal: 0,
            },
            d_in:{
                total: 0,
                vat: 0,
                ftotal: 0,
            },
            d_ret:{
                total: 0,
                vat: 0,
                ftotal: 0,
            },
            d_pur:{
                total: 0,
                vat: 0,
                ftotal: 0,
            },
            d_inv:{
                total: 0,
                vat: 0,
                ftotal: 0,
            },
            inall_totoal: 0,
            inall_vat: 0,
            inall_ftotoal: 0,
            outall_totoal: 0,
            outall_vat: 0,
            outall_ftotoal: 0,
            sub_total: 0,
            sub_vat: 0,
            sub_ftotoal: 0,
            sd: {
                day: 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            ed: {
                day: (new Date()).getDate() + 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            }
        }
    },
    computed: {
        _d_inv: function(){
            let t = {
                vat: 0,
                total: 0,
                ftotal: 0
            }
            for(let i=0;i<this.invRows.length;i++){
                t = {
                    vat: parseFloat(t.vat) + parseFloat(this.invRows[i].vat),
                    total: parseFloat(t.total) + parseFloat(this.invRows[i].total),
                    ftotal: parseFloat(t.ftotal) + parseFloat(this.invRows[i].ftotal)
                }
            }
            return {
                vat: this.$RoundNum(t.vat),
                total: this.$RoundNum(t.total),
                ftotal: this.$RoundNum(t.ftotal),
            }
        },
        _d_pur: function(){
            let t = {
                vat: 0,
                total: 0,
                ftotal: 0
            }
            for(let i=0;i<this.purRows.length;i++){
                t = {
                    vat: parseFloat(t.vat) + parseFloat(this.purRows[i].vat),
                    total: parseFloat(t.total) + parseFloat(this.purRows[i].total),
                    ftotal: parseFloat(t.ftotal) + parseFloat(this.purRows[i].ftotal)
                }
            }
            return {
                vat: this.$RoundNum(t.vat),
                total: this.$RoundNum(t.total),
                ftotal: this.$RoundNum(t.ftotal),
            }
        },
        _d_exp: function(){
            let t = {
                vat: 0,
                total: 0,
                ftotal: 0
            }
            for(let i=0;i<this.expense.length;i++){
                t = {
                    vat: parseFloat(t.vat) + parseFloat(this.expense[i].vat),
                    total: parseFloat(t.total) + parseFloat(this.expense[i].total),
                    ftotal: parseFloat(t.ftotal) + parseFloat(this.expense[i].ftotal)
                }
            }
            return {
                vat: this.$RoundNum(t.vat),
                total: this.$RoundNum(t.total),
                ftotal: this.$RoundNum(t.ftotal),
            }
        },
        _d_ret: function(){
            let t = {
                vat: 0,
                total: 0,
                ftotal: 0
            }
            for(let i=0;i<this.retres.length;i++){
                t = {
                    vat: parseFloat(t.vat) + parseFloat(this.retres[i].vat),
                    total: parseFloat(t.total) + parseFloat(this.retres[i].total),
                    ftotal: parseFloat(t.ftotal) + parseFloat(this.retres[i].ftotal)
                }
            }
            return {
                vat: this.$RoundNum(t.vat),
                total: this.$RoundNum(t.total),
                ftotal: this.$RoundNum(t.ftotal),
            }
        },
        summ: function(){
            let t = {
                vat: 0,total: 0, ftotal: 0
            }
            t = {
                total: parseFloat(this._d_inv.total) - parseFloat(this._d_ret.total) - parseFloat(this._d_pur.total) - parseFloat(this._d_exp.total),
                vat: parseFloat(this._d_inv.vat) - parseFloat(this._d_ret.vat) - parseFloat(this._d_pur.vat) - parseFloat(this._d_exp.vat),
                ftotal: parseFloat(this._d_inv.ftotal) - parseFloat(this._d_ret.ftotal) - parseFloat(this._d_pur.ftotal) - parseFloat(this._d_exp.ftotal),
            }
            return {
                vat: this.$RoundNum(t.vat),
                total: this.$RoundNum(t.total),
                ftotal: this.$RoundNum(t.ftotal),
            }
        },
        
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        sdate: function() {
            return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
            return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
            const start_year = this.$store.state.licenseType.startYear;
            let t = [{text: this.lang.year, value: -1}];
            for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
                t.push({text: start_year + i, value: start_year+i})
            }
            
            }
            return t
        },
        daysList: function(){
            return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
            ]
        },
        monthsList: function(){
            return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            ]
        },
        items: function(){
            return {
                    text: this.lang.vat_report,
                    disabled: true,
                    to: '/reports/vat-reports',
                }
        },
        groupid: function() {
            return this.$store.state.group_id;
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        }
    },
    methods: {
        getStartDate(){
            let d = (new Date()).getDate();
            let m = (new Date()).getMonth() + 1;
            let y = (new Date()).getFullYear();
            if(parseInt(d) < 30){
            d = parseInt(30) - parseInt(d);
            if(parseInt(m) == 1){
                m = 12
                y = parseInt(y) - 1
            }
            else if(parseInt(m) > 1){
                m = parseInt(m) - 1;
            }
            }else if(parseInt(d) == 30){
            d = 1;
            }else{
            d = parseInt(d) - parseInt(30);
            }
            this.sd.day = 1;
            this.sd.month = 1;
            this.sd.year = y;
        },
        preparedata(){
            ///////////////////////////
            //--- sales
            const salesTable  = this.lang.sales_invoices;
            const salesHeader = [
                this.lang.date,
                this.lang.invoiceno,
                this.lang.total,
                this.lang.salse_vat,
                this.lang.ftotal

            ]
            const salesData = this.invRows;
            const salesTotal = ["",this.lang.total, this.d_inv.total,this.d_inv.vat,this.d_inv.ftotal]
            //--- purch
            const purchTable  = this.lang.purchase_bill;
            const purchHeader = [
                this.lang.date,
                this.lang.invoiceno,
                this.lang.total,
                this.lang.bill_vat,
                this.lang.ftotal
            ]
            const purchData = this.purRows;
            const purTotal = ["",this.lang.total, this.d_pur.total,this.d_pur.vat,this.d_pur.ftotal]

            //--- expense
            const expenTable  = this.lang.purchase_bill;
            const expenHeader = [
                this.lang.date,
                this.lang.invoiceno,
                this.lang.total,
                this.lang.bill_vat,
                this.lang.ftotal
            ]
            const expenData = "";
            const expenTotal = ["",this.lang.total, 0,0,0]

            //--- return
            const returnTable  = this.lang.purchase_bill;
            const returnHeader = [
                this.lang.date,
                this.lang.invoiceno,
                this.lang.total,
                this.lang.bill_vat,
                this.lang.ftotal
            ]
            const returnData = "";
            const returnTotal = ["",this.lang.total, 0,0,0]
            /////////////////////////////////

            this.$refs.exportbar.sales = {
                title: salesTable,
                header: salesHeader,
                data: salesData,
                totals: salesTotal,
            }
            this.$refs.exportbar.inv_return = {
                title: returnTable,
                header: returnHeader,
                data: returnData,
                totals: returnTotal,
            }
            this.$refs.exportbar.purchase = {
                title: purchTable,
                header: purchHeader,
                data: purchData,
                totals: purTotal,
            }

            this.$refs.exportbar.expenses = {
                title: expenTable,
                header: expenHeader,
                data: expenData,
                totals: expenTotal,
            }


            this.$refs.exportbar.sdate = this.sdate;
            this.$refs.exportbar.edate = this.edate;
            this.$refs.exportbar.exporttitle = this.lang.vat_report;
            // if(this.selected == 1)
            //     this.$refs.exportbar.exporttitle = this.lang.ment_req_cards_new;
            // if(this.selected == 4)
            //     this.$refs.exportbar.exporttitle = this.lang.ment_req_cards_late;
            // if(this.selected == 3)
            //     this.$refs.exportbar.exporttitle = this.lang.ment_req_cards_closed;
            // this.$refs.exportbar.palte_number = this.plate_number;
            // this.$refs.exportbar.contact_number = this.mobile;
        },
        exportE(type){
            const post = new FormData();
            post.append("export","vatreport");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type",type);
            post.append('sdate',this.sdate);
            post.append('edate',this.edate);
            axios.post(
                this.$store.state.SAMCOTEC.e_path , post
            ).then((response) => {
                const res = response.data;
                window.open('../api/'+res.url,'_blank');
            })
        },
        getReport(){
            const post = new FormData();
            post.append("type" , 'getVatReport');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
            post.append("data[sdate]" , this.sdate);
            post.append("data[edate]" , this.edate);

            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.incomeRows = res.results.data.inres;
                this.outRows = res.results.data.outres;
                this.purRows = res.results.data.purres;
                this.invRows = res.results.data.invRows;
                this.retres = res.results.data.retres;
                this.expense = res.results.data.expense;
            })
        }
    },
    created() {
        this.getStartDate();
        this.getReport();
    },
}
</script>